<template>
    <div>
        <Header/>
        <!-- A Few Words About Our Bank-->
        <section class="section section-lg bg-gray-100 bg-light-figure" style="padding-top:200px">
            <div class="container">
                <div class="row row-20 justify-content-center justify-content-lg-between">
                    <div class="col-md-10 col-lg-6 wow fadeIn"><img class="out-of-container" src="@/assets/images/main-slide-3.png" alt="" width="691" height="452"/>
                    </div>
                    <div class="col-md-10 col-lg-6">
                        <div class="block-lg text-left">
                        <h2>{{ $t('bottom.face_recognition') }}</h2>
                        <!-- <p class="sup pr-xl-5">We're a world-class web hosting company who offers a range of hosting services, including web hosting, reseller hosting,</p> -->
                        </div>
                        <div class="text-block-2" style="margin-top: 50px">
                        <p>{{ $t('faceR.desc') }}</p>
                        <div class="progress-linear-wrap">
                            
                        </div><a class="button button-lg button-secondary" href="/#about">查看更多</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="function">
            <div class="container" style="max-width:1140px">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="section-heading text-uppercase">{{ $t('faceR.demo') }}</h2>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8 col-sm-12" id="slide">
                        <div class="div_demo">
                            <p id="demo_similar">{{ $t('faceR.similarity') }}： 0</p>
                            <img id="img_demo1" class="img_demo1" src="@/assets/images/demo/face/11.jpg">
                            <img id="img_demo2" class="img_demo2" src="@/assets/images/demo/face/12.jpg">
                            <div class="bg_img_upload"></div>
                            <div class="img_upload">
                                <a href="javascript:;" class="file">{{ $t('faceR.file') }}
                                    <input accept="image/png,image/jpeg,image/jpg" class="idimg" name="file" type="file"
                                           v-on:change="uploadIMG($event, 1)">
                                </a>
                                <a href="javascript:;" class="file">{{ $t('faceR.file') }}
                                    <input accept="image/png,image/jpeg,image/jpg" class="idimg" name="file" type="file"
                                           v-on:change="uploadIMG($event, 2)">
                                </a>
                            </div>
                        </div>
                        <div id="slide-list" ref="slideList">
                            <img :style="thumbnail" src="@/assets/images/demo/face/1.jpg" @click="localRecognition(1)">
                            <img :style="thumbnail" src="@/assets/images/demo/face/2.jpg" @click="localRecognition(2)">
                            <img :style="thumbnail" src="@/assets/images/demo/face/3.jpg" @click="localRecognition(3)">
                            <img :style="thumbnail" src="@/assets/images/demo/face/4.jpg" @click="localRecognition(4)">
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 panel" id="panel" ref="panel">
                        <h4 class="panel-title" @click="clickResponse(0)">{{ $t('faceR.result') }}</h4>
                        <div class="collapase" id="One">
                            <p></p>
                        </div>

                        <h4 class="panel-title" @click="clickResponse(1)">Request</h4>
                        <div class="collapase" id="Two">
                            <p></p>
                        </div>

                        <h4 class="panel-title" @click="clickResponse(2)">Response</h4>
                        <div class="collapase" id="Three">
                            <p></p>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <!-- Portfolio Grid -->
        <section class="bg-light" id="portfolio2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="section-heading text-uppercase">{{ $t('faceR.scene') }}</h2>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-md-4 col-sm-12 portfolio-item">
                        <img class="img-fluid" src="@/assets/images/recognition/recognition-3.jpg" alt="">
                        <div class="portfolio-caption mt-3">
                            <h5>{{ $t('faceR.scene1') }}</h5>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 portfolio-item">
                        <img class="img-fluid" src="@/assets/images/recognition/recognition-2.jpg" alt="">
                        <div class="portfolio-caption mt-3">
                            <h5>{{ $t('faceR.scene2') }}</h5>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 portfolio-item">
                        <img class="img-fluid" src="@/assets/images/recognition/recognition-1.jpg" alt="">
                        <div class="portfolio-caption mt-3">
                            <h5>{{ $t('faceR.scene3') }}</h5>
                        </div>
                    </div>

                </div>
            </div>
        </section>


        <section id="services">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="section-heading">{{ $t('homepage.advantage') }}</h2>
                        <hr class="my-4">
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 text-center">
                        <div class="service-box mt-5 mx-auto">
                            <span class="fa fa-4x fa-cogs text-primary mb-3 sr-icon-1"></span>
                            <h4 class="mb-3">{{ $t('faceR.advantage_title1') }}</h4>
                            <p class="text-muted mb-0">{{ $t('faceR.advantage_desc1') }}</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 text-center">
                        <div class="service-box mt-5 mx-auto">
                            <span class="fa fa-4x fa-briefcase text-primary mb-3 sr-icon-2"></span>
                            <h4 class="mb-3">{{ $t('faceR.advantage_title2') }}</h4>
                            <p class="text-muted mb-0">{{ $t('faceR.advantage_desc2') }}</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 text-center">
                        <div class="service-box mt-5 mx-auto">
                            <span class="fa fa-4x fa-globe text-primary mb-3 sr-icon-3"></span>
                            <h4 class="mb-3">{{ $t('faceR.advantage_title3') }}</h4>
                            <p class="text-muted mb-0">{{ $t('faceR.advantage_desc3') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Bottom/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Header from '@/components/Header.vue'
    import Bottom from "@/components/Bottom";
    import axios from 'axios';
    import initFunc from '@/assets/js/script'

    export default {
        name: 'Home',
        components: {
            Bottom, Header
        },
        metaInfo: {
            title: "智云视图-专注移动端高效视觉算法研发"
        },
        mounted() {
            this.clientWidth = this.$refs.slideList.clientWidth;
            this.localRecognition(1)
            this.clickResponse(0)
            initFunc()
        },
        data() {
            return {
                clientWidth: 760,
                leftImage: null,
                rightImage: null
            }
        },
        computed: {
            thumbnail() {
                return {
                    'padding-left': '10px',
                    width: '25%',
                    height: (this.clientWidth * 0.15) + 'px',
                    'object-fit': 'fill'
                }
            }
        },
        methods: {
            animate(obj, json) {
                //关闭上一个定时器
                clearInterval(obj.timer)

                obj.timer = setInterval(() => {
                    //规定动画是否开启
                    let flag = true;
                    //每次走的距离  距离 = 目标位置 - 当前位置
                    for (let arrt in json) {
                        let step = (json[arrt] - parseInt(this.getStyle(obj, arrt))) / 10;
                        // 距离取证
                        step = step > 0 ? Math.ceil(step) : Math.floor(step)
                        //让盒子动起来
                        obj.style[arrt] = parseInt(this.getStyle(obj, arrt)) + step + "px";
                        //

                        if (parseInt(this.getStyle(obj, arrt)) != json[arrt]) {
                            flag = false;
                        }
                    }
                    if (flag) {
                        clearInterval(obj.timer);
                    }

                }, 20)
            },
            getStyle(obj, arrt) {
                //ie垃圾兼容
                return obj.currentStyle ? obj.currentStyle[arrt] : getComputedStyle(obj, null)[arrt]
            },
            clickResponse(index) {
                let panel = document.getElementById("panel")
                let oDiv = panel.getElementsByTagName("div")
                for (var j = 0; j < oDiv.length; j++) {
                    // oDiv[j].style.height = 0;
                    this.animate(oDiv[j], {height: 0})
                }
                // oDiv[this.index].style.height = 210+"px";
                // if(parseInt(this.getStyle(oDiv[index],'height')) == 0){
                this.animate(oDiv[index], {height: this.$refs.panel.clientHeight - 146});
                //将文本添加到页面
                // oDiv[index].children[0].innerHTML =str[index]
                // }else{
                //     this.animate(oDiv[index],{height:0});
                // }
            },

            uploadIMG(e, index) {
                let files = e.target.files || e.dataTransfer.files
                if (!files.length) return
                this.picavalue = files[0]
                if (this.picavalue.size / 1024 > 2048) {
                    alert("请上传小于2M的图片")
                } else {
                    this.imgPreview(this.picavalue, index)
                }
            },
            //获取图片
            imgPreview(file, index) {
                let self = this
                //判断支不支持FileReader
                if (!file || !window.FileReader) return false
                if (/^image/.test(file.type)) {
                    //创建一个reader
                    let reader = new FileReader()
                    //将图片转成base64格式
                    reader.readAsDataURL(file)
                    //读取成功后的回调
                    reader.onloadend = function () {
                        let result = this.result
                        let image_demo = document.getElementById("img_demo" + index)
                        image_demo.src = result
                        if (file.size / 1024 < 300) {
                            result = result.replace(/^data:image\/\w+;base64,/, "");
                            if (index == 1) {
                                self.uploadImg(result, self.rightImage)
                            } else {
                                self.uploadImg(self.leftImage, result)
                            }
                        } else {
                            let img = new Image()
                            img.src = result
                            // console.log('********未压缩前的图片大小********')
                            // console.log(result.length / 1024)
                            img.onload = function () {
                                let data = self.compress(img, 0.3)
                                data = data.replace(/^data:image\/\w+;base64,/, "");
                                if (index == 1) {
                                    self.uploadImg(data, self.rightImage)
                                } else {
                                    self.uploadImg(self.leftImage, data)
                                }
                            }
                        }
                    }
                }
            },
            // 压缩图片
            compress(img, size) {
                let canvas = document.createElement('canvas')
                let ctx = canvas.getContext('2d')
                // let initSize = img.src.length
                let width = img.width
                let height = img.height
                canvas.width = width
                canvas.height = height
                // 铺底色
                ctx.fillStyle = '#fff'
                ctx.fillRect(0, 0, canvas.width, canvas.height)
                ctx.drawImage(img, 0, 0, width, height)
                //进行最小压缩
                let ndata = canvas.toDataURL('image/jpeg', size)
                // console.log('*******压缩后的图片大小*******')
                // console.log(ndata)
                // console.log(ndata.length / 1024)
                return ndata
            },
            uploadImg(image1, image2) {
                this.leftImage = image1
                this.rightImage = image2
                axios.post('/v1/face_compare', {
                    image1: image1,
                    image2: image2
                }).then((response) => {
                        // console.log(response);
                        let panel = document.getElementById("panel")
                        let similar = document.getElementById("demo_similar")
                        let oDiv = panel.getElementsByTagName("div")
                        let result = response.data
                        let str = ["<p>相似度：" + result.sim + "</p>", "<p>{\n  image1:\"图片的Base64编码\",\n  image2:\"图片的Base64编码\"\n}</p>", JSON.stringify(response.data, null, 4)]
                        for (let j = 0; j < oDiv.length; j++) {
                            oDiv[j].children[0].innerHTML = str[j].replace(/ /ig, '&nbsp;')
                        }
                        similar.innerHTML = "相似度：" + (result.sim * 100).toFixed(2) + "%"
                    })
                    // .catch(function (error) {
                    //     console.log(error);
                    // });
            },
            localRecognition(index) {
                let img_demo1 = document.getElementById('img_demo1');
                let img_demo2 = document.getElementById('img_demo2');
                let img1 = new Image()
                let img2 = new Image()
                img1.src = require("../assets/images/demo/face/" + index + "1.jpg")
                img2.src = require("../assets/images/demo/face/" + index + "2.jpg")
                img_demo1.src = require("../assets/images/demo/face/" + index + "1.jpg")
                img_demo2.src = require("../assets/images/demo/face/" + index + "2.jpg")
                img1.onload = () => {
                    let data1 = this.compress(img1, 0.3)
                    data1 = data1.replace(/^data:image\/\w+;base64,/, "");
                    img2.onload = () => {
                        let data2 = this.compress(img2, 0.3)
                        data2 = data2.replace(/^data:image\/\w+;base64,/, "");
                        this.uploadImg(data1, data2)
                    }
                }
                // img1.onerror = function (error) {
                //     console.log(error)
                // }
            },
        }
    }

</script>

<style scoped>
    header.masthead {
        padding-top: 10rem;
        padding-bottom: calc(10rem - 56px);
        background-image: url("../assets/images/recognition/header_banner.jpg");
        background-position: center center;
        background-size: cover;
    }

    header.masthead hr {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    header.masthead h1 {
        font-size: 2rem;
    }

    header.masthead p {
        font-weight: 300;
    }

    @media (min-width: 768px) {
        header.masthead p {
            font-size: 1.15rem;
        }
    }

    @media (min-width: 992px) {
        header.masthead {
            height: 50vh;
            min-height: 450px;
            padding-top: 0;
            padding-bottom: 0;
        }
        header.masthead h1 {
            font-size: 3rem;
        }
    }

    @media (min-width: 1200px) {
        header.masthead h1 {
            font-size: 4rem;
        }
    }

    .text-primary {
        color: #5D92E3 !important;
    }

    .service-box {
        max-width: 400px;
    }

    #portfolio .portfolio-item {
        right: 0;
        margin: 0 0 15px;
        text-align: center;
    }

    #portfolio .portfolio-item .portfolio-link {
        position: relative;
        display: block;
        max-width: 400px;
        margin: 0 auto;
        cursor: pointer;
        background: #FFFFFF;
    }

    #portfolio .portfolio-item .portfolio-link .portfolio-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-transition: all ease 0.5s;
        transition: all ease 0.5s;
        opacity: 0;
        background: rgba(93, 146, 227, 0.8);
    }

    #portfolio .portfolio-item .portfolio-link .portfolio-hover:hover {
        opacity: 1;
    }

    #portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
        font-size: 20px;
        position: absolute;
        top: 50%;
        width: 100%;
        height: 20px;
        margin-top: -12px;
        text-align: center;
        color: white;
    }

    #portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content i {
        margin-top: -12px;
    }

    #portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h3,
    #portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h4 {
        margin: 0;
    }

    #portfolio .portfolio-item .portfolio-caption {
        max-width: 400px;
        margin: 0 auto;
        padding: 25px;
        text-align: center;
    }

    #portfolio .portfolio-item .portfolio-caption h4 {
        margin: 0;
        text-transform: none;
    }

    #portfolio .portfolio-item .portfolio-caption p {
        font-size: 16px;
        font-style: italic;
        margin: 0;
        font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }

    #portfolio * {
        z-index: 2;
    }

    @media (min-width: 767px) {
        #portfolio .portfolio-item {
            margin: 0 0 30px;
        }
    }

    #portfolio img {
        max-width: 350px;
    }

    .service-box {
        max-width: 400px;
    }

    section {
        padding: 3rem 0;
    }

    .panel{
        height: auto;
        background-color: #F3F3F3;
        padding: 0;
    }
    .panel-title{
        width: 100%;
        height: 40px;
        font:400 18px/50px '微软雅黑';
        color: #333;
        cursor: pointer;
        margin-left: 15px;
    }
    .collapase{
        width: 100%;
        height: 0;
        background: #F3F3F3;
        overflow: scroll;
        border-bottom:1px solid #DDD;
        white-space: pre-line;
    }
    .collapase p{
        color: #999;
        padding: 20px;
    }

    @keyframes show{
        0%{height: 0;}
        100%{height: 210px;}
    }
    @keyframes show2{
        0%{height: 210px;}
        100%{height: 0px;}
    }

    #slide {
        padding: 0;
        background: #EEE;
    }

    #slide-list {
        margin-top: 10px;
    }

    #slide-list img {
        cursor: pointer;
    }

    .div_demo {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 62.5%;
        background: #333333;
    }

    #demo_similar {
        position: absolute;
        top: 10%;
        left: 30%;
        width: 40%;
        margin: 0 auto;
        color: #EEEEEE;
        text-align: center;
        font-size: 25px;
    }

    .img_demo1 {
        position: absolute;
        top: 30%;
        left: 5%;
        width: 42%;
        height: 40%;
        object-fit: contain;
        margin: 0 auto;
    }

    .img_demo2 {
        position: absolute;
        top: 30%;
        right: 5%;
        width: 42%;
        height: 40%;
        object-fit: contain;
        margin: 0 auto;
    }

    .img_upload {
        background: #333333;
        position: absolute;
        top: 75%;
        left: 0;
        width: 100%;
        height: 10%;
    }

    .file{
        position:relative;
        display:inline-block;
        background:#333;
        border:1px solid #000;
        /*padding:5px 20px;*/
        /*border-radius:4px;*/
        overflow:hidden;
        color:#FFF;
        text-decoration:none;
        text-indent:0;
        line-height:220%;
        height: 100%;
        width: 20%;
        vertical-align:middle;
        text-align: center;
        margin-left: 15%;
        margin-right: 15%;
    }
    .file input{
        position:absolute;
        /* 防止在各浏览器中上传按钮的显示不同,给上传按钮设置文字大小 */
        font-size:100px;
        /* 解决IE中无法靠近边缘问题 */
        right:-5px;
        top:-5px;
        /* 设置透明度为0,针对非IE浏览器或高版本IE浏览器*/
        opacity:0;
        /* 设置透明度为0,这里针对低版本IE浏览器 */
        filter:alpha(opacity=0);
        cursor:pointer;
        background: #333333;
        vertical-align:middle;
    }
    .file:hover{
        background:#333;
        border-color:#000;
        color:#000;
        text-decoration:none;
    }
    #textId{
        width:80%;
        height:100%;
        border:1px solid #000000;
        /*border-radius:4px;*/
        vertical-align:top;
        background: #666666;
    }
</style>
